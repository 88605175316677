import { saveUserInServer, setAuthServerToken } from '@/context/auth/authServerActions'
import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { IdTokenResult, User } from 'firebase/auth'

/** Si existe autenticación del usuario, te actualiza la sesión en el back, sino te remueve todo lo necesario para que inicies sesión */
export function useUpdateTokens({
	options,
}: {
	options: UseMutationOptions<void, Error, User, unknown>
}) {
	return useMutation({
		mutationFn: async (user: User) => {
			const userClaims: IdTokenResult | undefined = await user?.getIdTokenResult()
			if (userClaims) {
				await saveUserInServer(userClaims)
				await setAuthServerToken(userClaims.token, userClaims.expirationTime)
			}
		},
		...options,
	})
}
