import posthog from 'posthog-js'

/**
 * Propiedades comunes para todos los eventos de logueo.
 */
interface LoginEventProperties {
	dni?: string
	email?: string
	step?: string
	text?: string
	provider_id?: string
	error_message?: string
}

/**
 * Envía un evento de autenticación a PostHog, rastreando la acción realizada por el usuario.
 * 
 * @param {string} action - El nombre de la acción realizada.
 * @param {LoginEventProperties} properties - Un objeto con propiedades adicionales relacionadas con el evento.
 * 
 * @returns {void} No retorna ningún valor.
 */
function trackAuthEvent(action: string, properties?: LoginEventProperties): void {
	posthog.capture(`login_${action}`, properties)
}

/**
 * Envía un evento a PostHog indicando que el usuario ha completado el proceso de registro.
 * 
 * @returns {void} No retorna ningún valor.
 */
export function trackEventLoginWithEmail(): void {
	trackAuthEvent('continue_with_email', {})
}

/**
 * Envía un evento a PostHog indicando que el usuario debe cambiar su contraseña.
 * 
 * @returns {void} No retorna ningún valor.
 */
export function trackEventRequireChangePassword(): void {
	trackAuthEvent('require_change_password', {})
}

/**
 * Envía un evento a PostHog indicando que ocurrió un error durante el inicio de sesión con correo electrónico.
 * 
 * @param {string} errorMessage - El mensaje de error asociado con el fallo en el inicio de sesión.
 * 
 * @returns {void} No retorna ningún valor.
 */
export function trackEventLoginWithEmailError(errorMessage: string, email?: string): void {
	trackAuthEvent('email_error', { error_message: errorMessage, email })
}


/**
 * Envía un evento a PostHog indicando que el usuario se redirige a la página de registro.
 * 
 * @returns {void} No retorna ningún valor.
 */
export function trackEventRedirectToSignup(): void {
	trackAuthEvent('redirect_to_signup', {})
}

/** 
 * Envía un evento a PostHog indicando que el usuario ha iniciado sesión con un proveedor externo.
 */

export function trackEventLoginWithProvider(providerId: string): void {
	trackAuthEvent('login_with_provider', { provider_id: providerId })
}

/**
 * Envía un evento a PostHog indicando el primer paso del proceso de cambio de contraseña.
 */

export function trackEventChangePasswordDni(dni: string): void {
	trackAuthEvent('change_password_dni', { dni, step: '1' })
}

/**
 * Envía un evento a PostHog indicando que el usuario mando un email para cambiar su contraseña.
 */

export function trackEventSendPasswordReset(email: string): void {
	trackAuthEvent('send_password_reset', { email, step: '2' })
}

/**
 * Envía un evento a PostHog indicando que el usuario ha finalizado el proceso de cambio de contraseña.
 */

export function trackEventChangePasswordFinished(): void {
	trackAuthEvent('change_password_finished', { step: '3' })
}