import { FirebaseError } from 'firebase/app'
import { useMutation, UseMutationOptions } from 'react-query'
import { signInWithCustomToken } from 'firebase/auth'
import { auth as firebaseAuth } from '@/config/DBConnection'
import { saveUserInServer, setAuthServerToken, setCameFromIomaApp } from '@/context/auth/authServerActions'

type Options = Omit<UseMutationOptions<void, FirebaseError, { customToken: string, hasIomaToken?: boolean }>, 'mutationKey'| 'mutationFn'>

export const useSignInWithCustomToken = (options?: Options) => {
	return useMutation<void, FirebaseError, { customToken: string, hasIomaToken?: boolean }>({
		mutationKey: [ 'signInWithCustomToken' ],
		mutationFn: async ({ customToken, hasIomaToken = false }) => {
			const credential = await signInWithCustomToken(
				firebaseAuth,
				customToken,
			)
			const idTokenResult = await credential.user.getIdTokenResult()

			if (idTokenResult.token) {
				await saveUserInServer(idTokenResult)
				await setAuthServerToken(idTokenResult.token)
				if (hasIomaToken) {
					await setCameFromIomaApp()
				}
			}
		},
		...options,
	})
}