import { useMutation } from 'react-query'
import { auth } from '@/config/DBConnection'
import { getIdTokenResult } from 'firebase/auth'

export const useClaimsResult = () => {
	return useMutation(['Firebase-Query','getIdTokenResult'], async () => {
		if (!auth.currentUser) {
			throw new Error('Usuario no válido')
		}

		const idTokenResult = await getIdTokenResult(auth.currentUser)
		const requirePasswordChange = idTokenResult?.claims as { requirePasswordChange?: boolean }

		return requirePasswordChange
	})
}
